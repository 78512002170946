import { axios } from '@/utils/request'

export function fetchCommentsByTopicId(topic_Id) {
  return axios({
    url: '/community/comment/get_comments',
    method: 'get',
    params: {
      topicid: topic_Id
    }
  })
}

export function pushComment(data) {
  return axios({
    url: '/community/comment/add_comment',
    method: 'post',
    data: data
  })
}

export function getCommentsList(params) {
  return axios({
    url: '/community/comment/getCommentsList',
    method: 'get',
    params: params
  })
}
